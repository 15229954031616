import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import GAuth from 'vue-google-oauth2'

Vue.use(GAuth, {
  clientId: '319463914823-hl8jttps85rfr1ps9srepo879fio3a6p.apps.googleusercontent.com',

  // // Client ID for Dev
  // clientId: '780655493910-sstveves688grn35kjp3ogffate9p5rj.apps.googleusercontent.com',
})

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  render: h => h(App),
}).$mount('#app')
